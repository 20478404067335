import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";

import { AUTH_LOGOUT_REQUEST } from "../modules/Acceso/handlers/constants";
import { compose } from "redux";

import queryString from "query-string";

export class Header extends Component {
  state = {
    login: false,
    collapse: true,
    q: "",
  };

  constructor(props) {
    super();

    let { match = {}, location = {} } = props;
    const values = queryString.parse(location.search);

    this.state.q = values.q || "";
  }

  componentWillReceiveProps = (newProps) => {
    let { location = {}, history } = newProps;
    const values = queryString.parse(location.search);

    // this.setState({ q: values.q });

    window.scrollTo(0, 0);
  };

  onChangeBuscador = (event) => {
    let { location = {}, history } = this.props;

    let q = event.target.value || "";
    this.setState({ q: q });
  };

  onSubmitBuscador = (event) => {
    event.preventDefault();

    let { location = {}, history } = this.props;

    let q = this.state.q;
    history.push("/busqueda?q=" + q);
  };

  render() {
    let { match = {}, location = {} } = this.props;
    const values = queryString.parse(location.search);

    return (
      <div className={" border-bottom"} style={{ background: "white" }}>
        <header className="section-header">
          <section className="header-main">
            <div className="d-md-none " style={{ width: "100%" }}>
              <div className="container ">
                <div
                  className="navbar-light relative"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{this.renderMenuColapsable()}</div>
                  <div>
                    {this.renderAccesoEnlaces()} {this.renderCarritoContador()}
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                  <Link to="/" className="brand-wrap">
                    <img
                      className="logo"
                      src="/resource/logo-marca-yellow.png"
                      style={{ maxHeight: "36px" }}
                    />
                  </Link>
                </div>
                <div className="col-lg-5 col-md-5 col-12 col-sm-12">
                  {this.renderBuscador()}
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12  d-none d-md-inline-block">
                  <div className="widgets-wrap inline-block ">
                    {/* <div className="widget-header mr-3">
                      <Link
                        to="/menu"
                        className="icon icon-sm rounded-circle border"
                      >
                        <i className="fa fa-list-alt"></i>
                      </Link>
                    </div> */}
                    {this.renderCarritoContador()}
                    {this.renderAccesoEnlaces()}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </header>

        <nav
          className="navbar navbar-main navbar-light d-none d-md-flex"
          style={{ height: "55px", alignItems: "center" }}
        >
          <div className="container">
            <div
              className="row align-items-center no-margin"
              style={{ width: "100%" }}
            >
              <div className="col-lg-12 col-12 no-padding relative d-flex ">
                <div className="relative d-flex">
                  {this.renderMenuColapsable()}

                  <Link className="nav-link" to={`/menu`}>
                    Menu del día
                  </Link>
                  <Link className="nav-link" to={`/locales`}>
                    Locales
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }

  renderMenuColapsable = () => {
    const { collapse = true } = this.state;
    return (
      <>
        <button
          onClick={() => {
            console.log({ collapse });
            this.setState({ collapse: !collapse });
          }}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#main_nav"
          aria-controls="main_nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{
            fontSize: "15px",
            alignItems: "center",
            display: "inline-flex",
            color: "black",
            fontWeight: 600,
          }}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={(collapse ? "collapse" : "") + " navbar-collapse absolute"}
          style={{
            top: "0px",
            left: "1px",
            marginTop: "40px",
            padding: "4px 24px",
            background: "white",
            zIndex: "10",
            border: "1px solid #e3e3e3",
            borderRadius: "6px",
          }}
          id="main_nav"
        >
          <ul
            className="navbar-nav"
            style={{
              flexWrap: "wrap",
            }}
          >
            <li
              className="nav-item d-md-none"
              onClick={() => {
                this.setState({ collapse: true });
              }}
            >
              <Link className="nav-link" to={`/menu`}>
                Menu del día
              </Link>
            </li>
            {this.props.categorias_destacadas.map((categoria) => (
              <li
                key={categoria.id}
                className="nav-item"
                onClick={() => {
                  this.setState({ collapse: true });
                }}
              >
                <Link
                  className="nav-link"
                  style={{ textTransform: "capitalize" }}
                  to={`/categoria/${categoria.id}/${categoria.nombre}`}
                >
                  {categoria.nombre.toLocaleLowerCase()}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  renderCarritoContador = () => {
    return (
      <div className="widget-header mr-3">
        <Link to="/carrito" className="icon icon-sm rounded-circle border">
          <i className="fa fa-shopping-cart"></i>
        </Link>
        <span className="badge badge-pill badge-danger notify">
          {this.props.carrito_items.length}
        </span>
      </div>
    );
  };

  renderAccesoEnlaces = () => {
    return (
      <div className="widget-header icontext">
        {!!this.props.token && (
          <>
            <Link to="/perfil" className="icon icon-sm rounded-circle border">
              <i className="fa fa-user"></i>
            </Link>
            <div className="text">
              <div>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.props.dispath({
                      type: AUTH_LOGOUT_REQUEST,
                    })
                  }
                >
                  Salir
                </a>
              </div>
            </div>
          </>
        )}
        {!this.props.token && (
          <div className="text">
            <div>
              <Link to="/ingresar">Ingresar</Link> |
              <Link to="/registro"> Registro</Link>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderBuscador = () => {
    return (
      <form action="#" className="search" onSubmit={this.onSubmitBuscador}>
        <div className="input-group w-100">
          <input
            type="text"
            className="form-control"
            placeholder="Buscar un producto"
            value={this.state.q || ""}
            onChange={this.onChangeBuscador}
          />
          <div className="input-group-append">
            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>
      </form>
    );
  };
}

const mapStateToProps = (state) => ({
  categorias_destacadas: state.auth.categorias_destacadas,
  carrito_items: state.carrito.carrito_items,
  token: state.auth.token,
});

export default compose(
  connect(mapStateToProps, (dispath) => ({ dispath })),
  withRouter
)(Header);
