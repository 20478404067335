import React from "react";
import CategoriaHeader from "../../Categoria/components/CategoriaHeader";

const sucursales = [
  {
    nombre: "TRINIDAD",
    direccion: "Avda. Santísima Trinidad 3164 esq. Bogota",
    telefono: ["(021) 281 828", "(021) 280 212"],
    whatsapp: "595985670006",
    correo: "godspan.trinidad@godspan.com.py",
    horario: "Lunes a Domingos de 7:00 hs. a 21:00 hs.",
    disponible: "Para consumir en el local, buscar y para delivery.",
  },
  {
    nombre: "CARMELITAS",
    direccion: "Avda. Cabo Primero Feliciano Marecos e/Cabo Primero Pujol.",
    telefono: ["(021) 292 938", "(021) 292 940"],
    whatsapp: "595985670006",
    correo: "godspan.carmelitas@godspan.com.py",
    horario: "Lunes a Domingos de 7:00 hs. a 21:00 hs.",
    disponible: "Para consumir en el local, buscar y para delivery.",
  },
  {
    nombre: "VILLA MORRA",
    direccion: "Avda. Rca. Argentina 809 esq. Souza",
    telefono: ["(021) 600 823", "(021) 607 705"],
    whatsapp: "595985670006",
    correo: "godspan.villamorra@godspan.com.py",
    horario: "Lunes a Domingos de 7:00 hs. a 21:00 hs.",
    disponible: "Para consumir en el local, buscar y para delivery.",
  },
  {
    nombre: "VILLA MORRA 2",
    direccion: "Avda. Rca. Argentina Nº 201 c/ Mcal. López",
    telefono: ["(021) 290-603"],
    whatsapp: "595985670006",
    correo: "godspan.villamorra2@godspan.com.py",
    horario: "Lunes a Domingos: 7:00 hs. a 20:00 hs.",
    disponible: "Para consumir en el local, buscar y para delivery.",
  },
  {
    nombre: "ESPAÑA",
    direccion: "Avda. España N 2565º c/Santa Rosa",
    telefono: ["(021) 604 725", "(021) 662 915"],
    whatsapp: "595985670006",
    correo: "godspan.espana@gmail.com",
    horario: "Lunes a Domingos de 7:00 hs. a 20:00 hs.",
    disponible: "Para consumir en el local, buscar y para delivery.",
  },
  {
    nombre: "FERNANDO DE LA MORA",
    direccion: "Avda. Mariscal López 3434 casi Ingavi",
    telefono: ["(021) 683-584", "(021) 683-585"],
    whatsapp: "595985670006",
    correo: "godspan.fernando@gmail.com",
    horario: "Lunes a Domingos de 7:00 hs. a 22:00 hs.",
    disponible: "Para consumir en el local, buscar y para delivery.",
  },
];

const LocalesPage = () => (
  <div>
    <div>
      <CategoriaHeader nombre={<span>locales</span>}></CategoriaHeader>
      <section className="section-content">
        <div className="container padding-y-sm">
          <div className="row">
            {sucursales.map((local) => (
              <div
                key={local.nombre}
                className="col-12 col-md-6"
                style={{ marginBottom: "24px" }}
              >
                <Local local={local}></Local>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  </div>
);

export const Local = ({ local = {} }) => {
  return (
    <div className="card" style={{ width: "100%" }}>
      <div className="card-body">
        <div>
          <h5 style={{ color: "#fdbf00", marginBottom: 0 }}>{local.nombre}</h5>
        </div>
        <div style={{ marginBottom: "8px" }}>
          <i>{local.direccion}</i>
        </div>
        <div style={{ marginBottom: "0px" }}>{local.horario}</div>
        <div style={{ marginBottom: "8px" }}>{local.disponible}</div>
        <div style={{ marginBottom: "0px" }}>
          Telefonos:{" "}
          {local.telefono.map((t, idx) => (
            <span key={idx}>
              <a href={"tel:" + t}>{t}</a>
              {idx < local.telefono.length - 1 && " - "}
            </span>
          ))}
        </div>
        <div style={{ marginBottom: "0px" }}>
          WhatsApp:{" "}
          <a target="_blank" href={"https://wa.me/" + local.whatsapp}>
            +{local.whatsapp}
          </a>
        </div>
        <div style={{ marginBottom: "4px" }}>
          Email: <a href={"mailto:" + local.correo}>{local.correo}</a>
        </div>
      </div>
    </div>
  );
};

export default LocalesPage;
