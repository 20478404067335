import React, { Component } from "react";

export class CategoriaHeader extends Component {
  render() {
    return (
      <section className="categoria-header section-content bg">
        <div className="container">
          <header
            className="section-heading"
            style={{
              display: "flex",
              alignItems: "center",
              padding: 0,
              height: "80px",
            }}
          >
            <i>
              <h3 className="section-title" style={{ margin: 0 }}>
                <img
                  src="/resource/logo-marca-yellow.png"
                  style={{ padding: "4px" }}
                />
                <span>
                  {this.props.nombre ? this.props.nombre : "Productos"}
                </span>
              </h3>
            </i>
          </header>
        </div>
      </section>
    );
  }
}

export default CategoriaHeader;
