import React, { Component } from "react";
import { Link } from "react-router-dom";
import CategoriaHeader from "../components/CategoriaHeader";
import ProductoCard from "../../Producto/components/ProductoCard";
import CategoriaFiltro from "../components/CategoriaFiltro";
import { connect } from "react-redux";

export class CategoriaPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    let categoria = this.getCategoria();

    if (!!window.gtag && !!categoria.nombre) {
      window.gtag("event", "page_view", {
        page_title: `Gods Pan - Categoria - ${categoria.nombre}`,
        page_location: window.location.pathname,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    let { match = {} } = this.props;
    let { params = {} } = match;

    let newMatch = newProps.match || {};
    let newParams = newMatch.params || {};

    let categoria = this.getCategoria();
    let categoriaNew = this.getCategoriaNew(newProps);

    if (categoria?.nombre != categoriaNew?.nombre) {
      window.scrollTo(0, 0);

      let categoria = this.getCategoriaNew(newProps);
      if (!!window.gtag && !!categoria.nombre) {
        window.gtag("event", "page_view", {
          page_title: `Gods Pan - Categoria - ${categoria.nombre}`,
          page_location: window.location.pathname,
        });
      }
    }
  }

  getCategoriaNew = (newProps) => {
    let { match = {} } = newProps;
    let { params = {} } = match;

    let categoria =
      (newProps.categorias || [])
        .filter((c) => c.id == params.categoria)
        .shift() || {};

    return categoria;
  };

  getCategoria = () => {
    let { match = {} } = this.props;
    let { params = {} } = match;

    let categoria =
      (this.props.categorias || [])
        .filter((c) => c.id == params.categoria)
        .shift() || {};

    return categoria;
  };

  render() {
    let categoria = this.getCategoria();

    return (
      <div>
        <CategoriaHeader nombre={categoria.nombre}></CategoriaHeader>
        <section className="section-content">
          <div className="container padding-y-sm">
            <div className="row">
              <aside className="col-md-3">
                <CategoriaFiltro categoria={categoria}></CategoriaFiltro>
              </aside>
              <main className="col-md-9">
                {this.renderResultadoInfo()}
                {this.renderResultadoProductos()}
                {this.renderResultadoPaginacion()}
              </main>
            </div>
          </div>
        </section>
      </div>
    );
  }

  renderResultadoInfo = () => {
    let datos = this.props.productos;
    let categoria = this.getCategoria();

    datos = datos.filter((p) => p.categorias?.includes(categoria.id));

    return (
      <header className=" pb-3">
        <div className="form-inline">
          <span className="mr-md-auto">
            {datos.length} Productos encontrados{" "}
          </span>
          {/* <div className="btn-group">
            <Link
              to="#"
              className="btn btn-outline-secondary"
              data-toggle="tooltip"
              title=""
              data-original-title="List view"
            >
              <i className="fa fa-bars"></i>
            </Link>
            <Link
              to="#"
              className="btn  btn-outline-secondary active"
              data-toggle="tooltip"
              title=""
              data-original-title="Grid view"
            >
              <i className="fa fa-th"></i>
            </Link>
          </div> */}
        </div>
      </header>
    );
  };

  renderResultadoPaginacion = () => {
    return;
    // <nav className="mt-4" aria-label="Page navigation sample">
    //   <ul className="pagination">
    //     <li className="page-item disabled">
    //       <Link className="page-link" to="#">
    //         Anterior
    //       </Link>
    //     </li>
    //     <li className="page-item active">
    //       <Link className="page-link" to="#">
    //         1
    //       </Link>
    //     </li>
    //     <li className="page-item">
    //       <Link className="page-link" to="#">
    //         2
    //       </Link>
    //     </li>
    //     <li className="page-item">
    //       <Link className="page-link" to="#">
    //         3
    //       </Link>
    //     </li>
    //     <li className="page-item">
    //       <Link className="page-link" to="#">
    //         Siguiente
    //       </Link>
    //     </li>
    //   </ul>
    // </nav>;
  };

  renderResultadoProductos = () => {
    let datos = this.props.productos;
    let categoria = this.getCategoria();

    datos = datos.filter((p) => {
      return p.categorias?.includes(categoria.id);
    });

    return (
      <div className="row">
        {datos.map((producto) => (
          <div className="col-md-4" key={producto.id}>
            <ProductoCard {...producto}></ProductoCard>
          </div>
        ))}
      </div>
    );
  };
}
const mapStateToProps = (state) => ({
  categorias: state.auth.categorias,
  productos: state.auth.productos,
});
export default connect(mapStateToProps)(CategoriaPage);
