import React from "react";
import { connect } from "react-redux";
import { AUTH_FAILURE, AUTH_REQUEST } from "../handlers/constants";

class IngresarCard extends React.Component {
  state = {
    email: "",
    password: "",
  };

  onChange = (name) => (event) => {
    const { dispatch } = this.props;
    dispatch({ type: AUTH_FAILURE, payload: "" });
    this.setState({ [name]: event.target.value });
  };

  submit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.dispatch({
      type: AUTH_REQUEST,
      payload: {
        email,
        password,
      },
    });
  };

  render() {
    const { login_error = "" } = this.props;
    return (
      <div className="card">
        <div className="card-body">
          <h4 className="card-title mb-4">Ingresar</h4>
          <form onSubmit={this.submit} action="/perfil">
            <div className="form-group">
              <label>Correo</label>
              <input
                name=""
                className="form-control"
                placeholder=""
                type="email"
                onChange={this.onChange("email")}
                value={this.state.email}
              />
            </div>
            <div className="form-group">
              {/* <a className="float-right" href="#">
                Recuperar
              </a> */}
              <label>Constraseña</label>
              <input
                className="form-control"
                placeholder=""
                type="password"
                onChange={this.onChange("password")}
                value={this.state.password}
              />
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary btn-block">
                Ingresar{" "}
              </button>
              <span
                style={{
                  fontSize: "12px",
                  color: "red",
                  fontStyle: "italic",
                }}
              >
                {login_error}
              </span>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  login_error: state.auth.login_error,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(IngresarCard);
