import React, { Component } from "react";
import { connect } from "react-redux";
import AreaMap from "../../../common/AreaMap";
import { SET_ADICIONALES, SET_DIRECCIONES } from "../handlers/constants";

class PerfilPage extends Component {
  state = {
    sucursal: {},
    punto: {},
    direccion_formulario: false,
    direccion_editar: {},
    direcciones: [],
    adicionales_editar: {},
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    let { user = {} } = this.props;
    let { adicionales = {} } = user;

    this.setState({ adicionales_editar: adicionales || {} });

    if (!!window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "Gods Pan - Perfil",
        page_location: window.location.pathname,
      });
    }
  }

  onChangeAdicionales = (name) => (event) => {
    let { adicionales_editar = {} } = this.state;
    adicionales_editar[name] = event.target.value;
    this.setState({ adicionales_editar });
  };

  onSubmitAdicionales = (event) => {
    event.preventDefault();
    let { adicionales_editar = {} } = this.state;

    this.props.dispatch({
      type: SET_ADICIONALES,
      payload: { adicionales: adicionales_editar },
    });
  };

  render() {
    let { adicionales_editar = {} } = this.state;

    return (
      <>
        <div>
          <section className="section-content">
            <div className="container padding-y-sm">
              <form
                onSubmit={this.onSubmitAdicionales}
                className="row"
                style={{ justifyContent: "center" }}
              >
                <div className="card col-md-9">
                  <div className="card-body">
                    <h4 className="card-title mb-3">Perfil</h4>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <label>Nombre</label>
                        <input
                          autocomplete="off"
                          type="text"
                          className="form-control"
                          value={adicionales_editar.nombre || ""}
                          onChange={this.onChangeAdicionales("nombre")}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Apellido</label>
                        <input
                          autocomplete="off"
                          type="text"
                          className="form-control"
                          value={adicionales_editar.apellido || ""}
                          onChange={this.onChangeAdicionales("apellido")}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Telefono</label>
                        <input
                          autocomplete="off"
                          type="text"
                          className="form-control"
                          value={adicionales_editar.telefono_1 || ""}
                          onChange={this.onChangeAdicionales("telefono_1")}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Otro telefono</label>
                        <input
                          autocomplete="off"
                          type="text"
                          className="form-control"
                          value={adicionales_editar.telefono_2 || ""}
                          onChange={this.onChangeAdicionales("telefono_2")}
                        />
                      </div>
                      <div className="col form-group">
                        <label>RUC / CI (opcional)</label>
                        <input
                          autocomplete="off"
                          type="text"
                          className="form-control"
                          value={adicionales_editar.ruc || ""}
                          onChange={this.onChangeAdicionales("ruc")}
                        />
                      </div>
                    </div>
                    <h4 className="card-title mb-3">Cambiar contraseña</h4>
                    <div className="form-row">
                      <div className="col form-group">
                        <label>Nueva contraseña</label>
                        <input
                          type="password"
                          className="form-control"
                          value=""
                        />
                      </div>
                      <div className="col form-group">
                        <label>Confirmar nueva contraseña</label>
                        <input
                          type="password"
                          className="form-control"
                          value=""
                        />
                      </div>
                    </div>
                    <center>
                      <button className="btn btn-primary">Actualizar</button>
                    </center>
                  </div>
                </div>
              </form>
            </div>
            {this.renderSeccionDireccion()}
            <div className="container padding-y-sm"></div>
          </section>
        </div>
      </>
    );
  }

  renderSeccionDireccion = () => {
    return (
      <div className="container padding-y-sm">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="card col-md-9">
            <div className="card-body">
              <h4 className="card-title mb-3">
                Direcciones de Envio{" "}
                <a
                  className="btn btn-sm btn-secondary float-right"
                  onClick={() =>
                    this.setState({
                      direccion_formulario: true,
                      direccion_editar: {},
                    })
                  }
                >
                  Agregar nueva
                </a>
              </h4>
              {!this.state.direccion_formulario && this.renderMisDirecciones()}
              {!!this.state.direccion_formulario &&
                this.renderAgregarDireccion()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderMisDirecciones = () => {
    let { direcciones = [] } = this.props;

    return (
      <div className="" style={{ paddingTop: "0px" }}>
        {direcciones.map((direccion) => (
          <a
            key={direccion.id}
            className="card"
            onClick={this.editarDireccion(direccion)}
          >
            <div
              className="card-body"
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "space-between",
              }}
            >
              {direccion.ciudad} - {direccion.barrio}. {direccion.direccion}
              <i className="fa fa-pen"></i>
            </div>
          </a>
        ))}
      </div>
    );
  };

  onChange = (id, point) => {
    let { direccion_editar = {} } = this.state;
    direccion_editar.sucursal = this.props.sucursales[id] || {};
    direccion_editar.punto = point || {};

    this.setState({ direccion_editar });
  };

  onChangeDireccionEditar = (name) => (event) => {
    let { direccion_editar = {} } = this.state;
    direccion_editar[name] = event.target.value;
    this.setState({ direccion_editar });
  };

  addDireccion = () => {
    let { direccion_editar = {} } = this.state;
    let { direcciones = [] } = this.props;

    let nuevo = false;
    if (!direccion_editar.id) {
      nuevo = true;
      direccion_editar.id = new Date().getTime();
    }

    if (!nuevo) {
      direcciones.map((direccion, idx) => {
        if (direccion.id == direccion_editar.id) {
          direcciones[idx] = direccion_editar;
        }
      });
    } else {
      direcciones.push(direccion_editar);
    }

    direccion_editar = {};
    this.setState(
      {
        direccion_editar,
        direccion_formulario: false,
      },
      () => {
        this.props.dispatch({
          type: SET_DIRECCIONES,
          payload: { direcciones },
        });
      }
    );
    console.log(JSON.stringify({ direcciones }));
  };

  editarDireccion = (direccion) => () => {
    let direccion_editar = direccion;
    this.setState({
      direccion_editar,
      direccion_formulario: true,
    });
  };

  renderAgregarDireccion = () => {
    let { direccion_editar = {} } = this.state;
    let { sucursal = {} } = direccion_editar;
    return (
      <div className="">
        <div className="form-row">
          <div className="col-12 form-group">
            <label>Dirección</label>
            <input
              type="text"
              value={direccion_editar.direccion || ""}
              className="form-control"
              onChange={this.onChangeDireccionEditar("direccion")}
            />
          </div>
          <div className="col-6 form-group">
            <label>Barrio</label>
            <input
              type="text"
              value={direccion_editar.barrio || ""}
              className="form-control"
              onChange={this.onChangeDireccionEditar("barrio")}
            />
          </div>
          <div className="col-6 form-group">
            <label>Ciudad</label>
            <input
              type="text"
              value={direccion_editar.ciudad || ""}
              className="form-control"
              onChange={this.onChangeDireccionEditar("ciudad")}
            />
          </div>
          <div className="col-12 form-group">
            <label>Referencia</label>
            <input
              type="text"
              value={direccion_editar.referencia || ""}
              className="form-control"
              onChange={this.onChangeDireccionEditar("referencia")}
            />
          </div>
          <div className="col-12 form-group">
            <label>Sucursal asignada</label>
            <input
              type="readonly"
              value={sucursal.name || ""}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-12">
          <AreaMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAGamRMBNFBB-x7Wrl-SSq-t4rqQiGRgBI"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            defaultCenter={{ lat: -25.3011551, lng: -57.580372 }}
            zoom={4}
            areas={this.props.sucursales}
            point={direccion_editar.punto}
            onChange={this.onChange}
          />
        </div>
        <center style={{ marginTop: "10px" }}>
          <button className="btn btn-primary" onClick={this.addDireccion}>
            Guardar
          </button>
        </center>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  direcciones: state.auth.direcciones,
  user: state.auth.user,
  sucursales: state.auth.sucursales,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PerfilPage);
